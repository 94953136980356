import React, { Suspense, lazy, useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../images/logo.png";

const ProductCard = lazy(() => import("../components/ProductCard"));
const About = lazy(() => import("../components/About"));
const LandingPage = lazy(() => import("../components/LandingPage"));
const Category = lazy(() => import("../components/Category"));

const Home = ({ products, onCategoryClick }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const filteredProducts =
    selectedCategory === "All"
      ? products
      : products.filter((product) => product.category === selectedCategory);

  return (
    <div>
      <Helmet>
        <title>Gerai Wirausaha Mandiri - Marketplace</title>
        <meta
          name="description"
          content="Gerai Wirausaha Mandiri (GWM) menawarkan sembako murah dan jasa konsultasi bimbingan belajar Matematika dan Statistika dekat Universitas Syiah Kuala. Pilihan ideal untuk mahasiswa dan masyarakat sekitar."
        />
        <meta
          property="og:title"
          content="Gerai Wirausaha Mandiri - Marketplace Sembako & Bimbingan Belajar"
        />
        <meta
          property="og:description"
          content="Temukan sembako murah dan layanan konsultasi bimbingan belajar di Gerai Wirausaha Mandiri, dekat Universitas Syiah Kuala. Solusi lengkap untuk kebutuhan harian dan akademik Anda."
        />
      </Helmet>

      <Suspense
        fallback={
          <div className="flex justify-center items-center w-full h-screen bg-gray-100">
            <div className="w-full max-w-4xl text-center p-8">
              <img
                src={logo}
                alt="Logo Gerai Wirausaha Mandiri"
                className="w-1/3 mx-auto mb-4"
              />
              <h1 className="font-semibold text-2xl mb-4">
                Gerai Wirausaha Mandiri
              </h1>
              <p className="text-lg mb-4">
                Sedang memuat... Mohon tunggu sebentar.
              </p>
              <div className="animate-pulse w-20 h-20 bg-blue-500 rounded-full mx-auto"></div>
            </div>
          </div>
        }
      >
        <LandingPage />
        <section className="mt-20 mb-5 mx-5" id="kategori">
          <Category
            onCategoryClick={setSelectedCategory}
            selectedCategory={selectedCategory}
          />
        </section>

        <section id="produk" className="py-10 sm:px-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            {filteredProducts.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>
        </section>
        <section id="about" className="flex justify-center items-center mb-16">
          <About />
        </section>
      </Suspense>
    </div>
  );
};

export default Home;
